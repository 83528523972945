import React from 'react'
import PropTypes from 'prop-types'
const Loading = ({ size = 'md' }) => (
  <div className="pt-1 ">
    <span className={`spinner-border spinner-border-${size}`}></span>
    <span className="ml-2"> Loading...</span>
  </div>
)
Loading.propTypes = {
  size: PropTypes.string.isRequired,
}
export default Loading
