import { createSlice } from '@reduxjs/toolkit'
import { submitMotoQuotationAction } from 'src/actions/quotation/quotation-action'
const buyStateObj = {
  isRiskFormValid: false,
  step: 0,
  payment: {},
  productCode: '',
  riskInfo: {},
  coverPeriod: {},
  coverInfo: {},
  policeHolder: {},
  premium: {},
  loading: false,
  error: false,
  message: false,
  success: false,
}

const BuyNewInsuranceInfo = createSlice({
  name: 'createNewPolice',
  initialState: buyStateObj,
  reducers: {
    validateRiskForm: (state, action) => {
      return { ...state, isRiskFormValid: action?.payload }
    },
    changeStep: (state, action) => {
      return { ...state, step: action.payload }
    },
    storeProduct: (state, action) => {
      return { ...state, productCode: action.payload }
    },
    persistRiskInfo: (state, action) => {
      return { ...state, riskInfo: action.payload }
    },
    persistCoverInfo: (state, action) => {
      return { ...state, coverInfo: action.payload }
    },
    persistPolicyHolderInfo: (state, action) => {
      return { ...state, policeHolder: action?.payload }
    },
    perisistCoverPeriod: (state, action) => {
      return { ...state, coverPeriod: action.payload }
    },
    paymentPersist: (state, action) => {
      return { ...state, payment: action.payload }
    },
  },
  extraReducers: (bP) => {
    bP.addCase(submitMotoQuotationAction.pending, (state) => {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    })
      .addCase(submitMotoQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
  },
})
export const {
  storeProduct,
  changeStep,
  validateRiskForm,
  persistRiskInfo,
  persistPolicyHolderInfo,
  paymentPersist,
  persistCoverInfo,
  perisistCoverPeriod,
} = BuyNewInsuranceInfo.actions
export default BuyNewInsuranceInfo.reducer
