import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationManager } from 'react-notifications'
import apiCall from 'src/backend/http'
import { changeStep } from 'src/slices/buyInsuranceSlice'

const submitMotoQuotationAction = createAsyncThunk(
  'insurance/moto/calculate-premium',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/moto/quotation', requestData)
      console.log(JSON.stringify(data))

      if (status == 200) {
        NotificationManager.success(data?.message) // Show success notification

        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        console.log(step)
        dispatch(changeStep(step + 1))
        // Dispatch change step action on success
      } else {
        NotificationManager.warning(`Warning/Error Found: ${data?.message}`) // Show warning for non-200 status
      }

      return data // Return data on success
    } catch (error) {
      console.log(JSON.stringify(error.response.data))

      NotificationManager.error(`Error: ${error.response.data?.message}`) // Changed to error notification

      return rejectWithValue(error.response.data) // Reject with error data
    }
  },
)
/*
const submitMotoQuotationAction = createAsyncThunk(
  'insurance/moto/calculate-premium',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/moto/quotation', requestData)
      console.log(JSON.stringify(data))
      if (status == 200) {
        NotificationManager.success(data?.message)
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        console.log(step)
        dispatch(changeStep(step + 1))
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      } else {
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      }

      return data
    } catch (error) {
      console.log(JSON.stringify(error.response.data))
      NotificationManager.warning(`Warning/Error Found:${error.response.data?.message}`)
      return rejectWithValue(error.response.data)
    }
  },
)*/
const submitFireQuotation = createAsyncThunk(
  'insurance/bk-tech/fire/quotation',
  async (postData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/fire/quotation', postData)
      if (status != 200) {
        NotificationManager.error(data.message)
        return rejectWithValue(data.message)
      } else {
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
      }
      return data
    } catch (error) {
      return rejectWithValue(error.response.data || error.message)
    }
  },
)
const sendQuotationToEmail = createAsyncThunk(
  'notification/email/send-quotation',
  async (postReq, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('notification/email/send-quotation', postReq)
      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)
const submitNoMotoQuotation = createAsyncThunk(
  'insurance/non-moto/calculate-premium',
  async (postData, toolKit) => {
    try {
      const {
        app: {
          bkProduct: { selectProduct },
        },
      } = toolKit.getState()
      let url =
        selectProduct.product_CODE === 'TRAVEL_INS'
          ? 'insurance/bk-tech/travel/quotation'
          : 'insurance/non-moto/calculate-premium'

      const { data } = await apiCall.post(url, postData)
      return data
    } catch (error) {
      return toolKit.rejectWithValue(error.response.data)
    }
  },
)
const submitPersonalAccidentQuotation = createAsyncThunk(
  'insurance/bk-tech/pa/quotation',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('/insurance/bk-tech/pa/quotation', postData)
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
const getNidaInformation = createAsyncThunk(
  'nida/customer-info',
  async (nationalId, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`nida/customer-info?documentNo=${nationalId}`)
      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)
const restSubmitionStatus = createAction('RESET_QUOTATION_SUBMISSION')
const showHideFirePreview = createAction('FIRE_PREVIEW')
const refleshNidaState = createAction('REFRESH_NIDA')
const keepItem = createAction('KEEP_SELECTED_ITEM')
const COVER_SELECTED = createAction('COVER_SELECTED')
const COMESA_COVER_SELECTED = createAction('COMESA_COVER_SELECTED')
const updatedCovers = createAction('UPDATED_COVERS')
const SELECTED_MOTO_TYPE = createAction('SELECTED_MOTO_TYPE')
export {
  submitMotoQuotationAction,
  restSubmitionStatus,
  SELECTED_MOTO_TYPE,
  submitNoMotoQuotation,
  submitPersonalAccidentQuotation,
  updatedCovers,
  COMESA_COVER_SELECTED,
  COVER_SELECTED,
  showHideFirePreview,
  submitFireQuotation,
  sendQuotationToEmail,
  getNidaInformation,
  refleshNidaState,
  keepItem,
}
