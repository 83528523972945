import { createSlice } from '@reduxjs/toolkit'
import {
  manageStepper,
  showAndHideSidebar,
  showHidePreviewQuotation,
} from 'src/actions/displayAction'
import { showHideFirePreview } from 'src/actions/quotation/quotation-action'
const initialState = {
  sidebarShow: true,
  previewQuotation: false,
  previewFire: false,
  stepper: {
    code: '',
    name: '',
    step: 0,
    desc: '',
  },
}

const displaySlice = createSlice({
  name: 'UI_DISPLAY',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(showAndHideSidebar, (state, action) => {
        return { ...state, sidebarShow: action.payload }
      })
      .addCase(showHideFirePreview, (state, action) => {
        return { ...state, previewFire: action?.payload }
      })
      .addCase(showHidePreviewQuotation, (state, action) => {
        return { ...state, previewQuotation: action.payload }
      })
      .addCase(manageStepper, (state, action) => {
        return { ...state, stepper: action?.payload }
      })
  },
})
export default displaySlice.reducer
