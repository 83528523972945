import React from 'react'
import PropTypes from 'prop-types'
const BkAlert = ({ message, code }) => (
  <div className={code === 'success' ? 'alert  alert-success' : 'alert alert-danger'}>
    {message}
  </div>
)
BkAlert.propTypes = {
  message: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}
export default BkAlert
