import React, { Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { useSelector } from 'react-redux'
import ForgetPassword from './views/pages/forgetPassword/forgetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Quatation = React.lazy(() => import('./layout/BkQuotationLayout'))
const MotoContracts = React.lazy(() => import('./views/contract_template/motoTemplate'))
const MotoCertificate = React.lazy(() => import('./views/certificate/MotoCertificate'))
const App = () => {
  const { token } = useSelector((state) => state.app?.userMngmt)
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="BK-GI Quotation" element={<Login />} />
          <Route exact path="/moto-contract" name="Moto Contract" element={<MotoContracts />} />
          <Route
            exact
            path="/motor-certificate"
            name="BK-GI Certificate"
            element={<MotoCertificate />}
          />
          <Route exact path="/register" name="BK-GI REGISTER" element={<Register />} />
          <Route exact path="/forget-pwd" name="Forget Password" element={<ForgetPassword />} />
          <Route exact path="/self-service" name="BK-GI Quotation" element={<Quatation />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" element={<DefaultLayout />} />
          {/*<Route path="*" element={token != null ? <DefaultLayout /> : <DefaultLayout />} />*/}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
